import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Heading from 'components/shared/Heading';
import Content from 'components/shared/Content';
import Icon from 'components/shared/Icon';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Autoplay]);

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 180px;
  filter: grayscale(100%);
  opacity: 0.4;
  transition: 0.35s;
  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
`;

const StyledContent = styled(Content)`
  max-width: 1440px;
`;

const SwiperWrapper = styled.div`
  margin-top: 10px;
  ${({ theme }) => theme.mq.xs} {
    margin-top: 30px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-top: 50px;
  }
`;

const Partners = ({ heading, cards }) => (
  <Section id="partners">
    <StyledContent>
      <Heading dangerouslySetInnerHTML={{ __html: heading }} />
      <SwiperWrapper>
        <Swiper
          loop
          spaceBetween={40}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          breakpoints={{
            1400: {
              slidesPerView: 9,
            },
            1300: {
              slidesPerView: 8,
            },
            1200: {
              slidesPerView: 7,
            },
            1024: {
              slidesPerView: 6,
            },
            768: {
              slidesPerView: 5,
            },
            680: {
              slidesPerView: 4,
            },
            570: {
              slidesPerView: 3,
            },
            380: {
              slidesPerView: 2,
            },
            320: {
              slidesPerView: 1,
            },
          }}
        >
          {cards.map(({ name, publicURL }, index) => (
            <SwiperSlide key={index}>
              <CardWrapper key={index}>
                <Icon src={publicURL} alt={name} auto />
              </CardWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
    </StyledContent>
  </Section>
);

Partners.propTypes = {
  heading: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Partners;
