import styled from 'styled-components';

import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';

export const HeadingText = styled.p`
  font-size: 2rem;
  font-weight: 700;

  ${({ theme }) => theme.mq.s} {
    font-size: 4rem;
    line-height: 5rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 5rem;
    line-height: 6rem;
  }
`;

export const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 400;

  text-decoration: none;
`;

export const Date = styled.p`
  font-size: 1.2rem;
  font-weight: 600;

  text-decoration: none;

  color: rgb(136, 136, 136);
`;

export const Title = styled.div`
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;

  text-decoration: none;

  color: black;
`;

export const BottomText = styled.div`
  font-size: 1.3rem;
  font-weight: 500;

  text-decoration: none;

  color: rgb(136, 136, 136);
`;

export const StyledContent = styled(Content)`
  max-width: 1440px;

  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const Heading = styled.div`
  position: relative;

  ${HeadingText} {
    padding-left: 24px;
  }

  &::before {
    content: '';
    width: 3px;
    height: 90%;

    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);

    background-color: ${({ theme }) => theme.colors.blue100};
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
  }
`;

export const Card = styled.div`
  max-width: 576px;
  height: 100%;

  flex: 1 1;

  margin: 0px auto;

  display: flex;
  flex-direction: column;

  text-decoration: none;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 320px;

  background-color: ${({ theme }) => theme.colors.blue100};
`;

export const Image = styled(LazyImage)`
  width: 100%;
  height: 100%;
`;

export const CardContent = styled.div`
  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border: 1px solid rgb(241, 241, 241);
`;

export const CardContentHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
