import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Heading from 'components/shared/Heading';
import Content from 'components/shared/Content';
import Text from 'components/shared/Text';
import LazyImage from 'components/shared/LazyImage';

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
  }
  @media (min-width: 1380px) {
    justify-content: space-between;
  }
`;

const Card = styled.article`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin-top: 40px;
  ${({ theme }) => theme.mq.xs} {
    max-width: 400px;
  }
  ${({ theme }) => theme.mq.md} {
    margin: 40px 20px 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 290px;
  height: 290px;
  margin: 0 auto;
`;

const Number = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.xxxl};
  padding: 5px;
  ${({ theme }) => theme.mq.xs} {
    top: 40px;
    left: -45px;
    bottom: auto;
    right: auto;
    background: transparent;
    color: ${({ theme }) => theme.colors.dark};
  }
`;

const StyledContent = styled(Content)`
  max-width: 1640px;
`;

const CardHeading = styled.h3`
  padding: 25px 0;
  font-size: 2.6rem;
  text-align: center;
  ${({ theme }) => theme.mq.md} {
    padding: 40px 0;
    font-size: 3rem;
  }
`;

const Process = ({ heading, cards }) => (
  <Section id="process">
    <StyledContent>
      <Heading dangerouslySetInnerHTML={{ __html: heading }} />
      <CardsWrapper>
        {cards.map(({ image, imageAlt, title, description }, index) => (
          <Card key={title}>
            <ImageWrapper>
              <LazyImage src={image} alt={imageAlt} cover />
              <Number>0{index + 1}</Number>
            </ImageWrapper>
            <CardHeading>{title}</CardHeading>
            <Text align="justify">{description}</Text>
          </Card>
        ))}
      </CardsWrapper>
    </StyledContent>
  </Section>
);

Process.propTypes = {
  heading: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Process;
