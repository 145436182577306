import styled, { css } from 'styled-components';

const Section = styled.section`
  position: relative;
  padding: ${({ padding }) => padding || '40px 0'};
  ${({ theme }) => theme.mq.lg} {
    padding: ${({ padding }) => padding || '60px 0'};
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: ${({ padding }) => padding || '80px 0'};
  }
  ${({ contact }) =>
    contact &&
    css`
      ${({ theme }) => theme.mq.xl} {
        padding-bottom: 120px;
      }
    `}
`;

export default Section;
