import React, { useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Heading from 'components/shared/Heading';
import Text from 'components/shared/Text';

const StyledSection = styled(Section)`
  padding-bottom: 0;
  ${({ theme }) => theme.mq.md} {
    padding-top: 0 !important;
    margin-top: 130px;
    padding-bottom: 50px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-top: 100px;
    padding-bottom: 0;
  }
`;

const SlideWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  margin-left: auto;
  ${({ theme }) => theme.mq.xs} {
    height: 600px;
  }
  ${({ theme }) => theme.mq.s} {
    min-width: 430px;
    min-height: 600px;
    max-width: 430px;
    max-height: 600px;
  }
  ${({ theme }) => theme.mq.md} {
    min-width: 320px;
    min-height: 500px;
    max-width: 320px;
    max-height: 500px;
  }
  ${({ theme }) => theme.mq.lg} {
    min-width: 430px;
    min-height: 600px;
    max-width: 430px;
    max-height: 600px;
    margin-left: 0;
  }
  ${({ theme }) => theme.mq.xl} {
    min-width: 530px;
    min-height: 730px;
    max-width: 530px;
    max-height: 730px;
  }
`;

const ImageWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
`;

const Slide = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  overflow: hidden;
  background-color: #000;
  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
    `}
`;

const ControlPanel = styled.div`
  display: flex;
  position: absolute;
  bottom: -30px;
  left: 50%;
  align-items: center;
  transform: translateX(-50%);
  z-index: 5;
  ${({ theme }) => theme.mq.s} {
    bottom: -50px;
  }
`;

const ControlButton = styled.div`
  width: 30px;
  height: 2px;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.6s;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.blue : theme.colors.gray300};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
  }
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  ${({ theme }) => theme.mq.md} {
    margin-left: 30px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-left: 50px;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-left: 100px;
  }
`;

const StyledHeading = styled(Heading)`
  padding: 0 !important;
  margin-bottom: 30px;
  ${({ theme }) => theme.mq.xl} {
    margin-bottom: 100px;
  }
`;

const BgWrapper = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: -250px;
  width: 530px;
  height: 730px;
  @media (min-width: 1550px) {
    display: block;
  }
  @media (min-width: 1650px) {
    left: -200px;
  }
  @media (min-width: 1800px) {
    left: -150px;
  }
`;

const ScrollWrapper = styled.div`
  display: none;
  position: absolute;
  ${({ theme }) => theme.mq.s} {
    display: block;
    left: -82px;
    top: 500px;
  }
  ${({ theme }) => theme.mq.md} {
    display: none;
  }
  ${({ theme }) => theme.mq.xxl} {
    display: block;
    left: auto;
    top: auto;
    right: -55px;
    bottom: 50px;
  }
`;

const RotatedText = styled.span`
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  margin-bottom: 70px;
  text-transform: uppercase;
  font-size: 1.8rem;
  &:after {
    content: '';
    height: 2px;
    width: 75px;
    margin-left: 35px;
    background: ${({ theme }) => theme.colors.dark};
  }
`;

const About = ({ slides, heading, content, bgImage }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const timeoutRef = useRef(null);

  const slide = useCallback(() => {
    const nextSlide = (activeSlide + 1) % slides.length;
    setActiveSlide(nextSlide);
  }, [activeSlide]);

  const changeToSlide = slideIndex => {
    if (!isAnimating) setActiveSlide(slideIndex);
  };

  useEffect(() => {
    const setSlideTimeout = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(slide, 3000);

      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 1000);
    };

    setSlideTimeout();

    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(setSlideTimeout);
    };
  }, [activeSlide, slide]);
  return (
    <StyledSection id="about">
      <Content>
        <ContentWrapper>
          <SlideWrapper>
            {slides.length > 1 && (
              <ControlPanel>
                {slides.map((_, index) => (
                  <ControlButton
                    key={index}
                    onClick={() => changeToSlide(index)}
                    isActive={index === activeSlide}
                    aria-label={`${index + 1} slide`}
                    role="button"
                  />
                ))}
              </ControlPanel>
            )}
            {slides.map(({ image, imageAlt }, index) => (
              <Slide key={index} isActive={index === activeSlide}>
                <ImageWrapper>
                  <LazyImage src={image} alt={imageAlt} cover />
                </ImageWrapper>
              </Slide>
            ))}
          </SlideWrapper>
          <ContentInner>
            <StyledHeading left>{heading}</StyledHeading>
            <Text dangerouslySetInnerHTML={{ __html: content }} />
          </ContentInner>
        </ContentWrapper>
      </Content>
      <ScrollWrapper>
        <RotatedText>Poznaj nas</RotatedText>
      </ScrollWrapper>
      <BgWrapper>
        <LazyImage src={bgImage} alt="background" />
      </BgWrapper>
    </StyledSection>
  );
};

About.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  heading: PropTypes.string.isRequired,
  bgImage: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  content: PropTypes.string.isRequired,
};

export default About;
