import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Button from 'components/shared/Button';
import Content from 'components/shared/Content';
import SectionHeading from 'components/shared/Heading';
import scrollToSection from 'utils/scrollToSection';
import ContactForm from 'components/Contact/ContactForm';

const CardsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Heading = styled.h2`
  font-size: 2.55rem;
  padding-bottom: 10px;
  ${({ theme }) => theme.mq.xs} {
    font-size: 3rem;
  }
  ${({ theme }) => theme.mq.md} {
    font-size: 3.6rem;
    padding-bottom: 30px;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.2rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 4.8rem;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 300px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue};
  &:nth-child(2) {
    background: ${({ theme }) => theme.colors.blue100};
  }
  ${({ theme }) => theme.mq.s} {
    height: 360px;
  }
  ${({ theme }) => theme.mq.lg} {
    justify-content: stretch;
    padding: 130px 30px 0;
    height: 480px;
  }
`;

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.dark};
  padding: 18px;
  margin-top: 30px;
  &:hover {
    background: ${({ theme }) => theme.colors.blue100};
    color: ${({ theme }) => theme.colors.white};
  }
  ${({ theme }) => theme.mq.s} {
    padding: 25px;
    max-width: 280px;
  }
`;

const Detail = styled.p`
  font-size: 1.8rem;
  line-height: 4rem;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
`;

const testTerms = [
  {
    name: 'terms',
    content: `Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci imienia i nazwiska, adresu e-mail, numeru telefonu, informacji dotyczących preferencji zakupowych i innych danych wskazanych w treści wiadomości oraz w zakresie informacji o mojej aktywności na stronie internetowej przez Polisa Centrum Ubezpieczeń Katarzyna Sterzel, z siedzibą w Koszalinie w celu marketingu bezpośredniego produktów i usług.`,
    required: true,
  },
];

const Partners = ({ card1, card2 }) => (
  <Section id="contact" contact>
    <CardsWrapper>
      <Card>
        <Heading>{card1.heading}</Heading>
        <StyledButton onClick={() => scrollToSection('#contact-form')}>
          {card1.btnText}
        </StyledButton>
      </Card>
      <Card as="address">
        <Heading>{card2.heading}</Heading>
        <Detail as="a" href={`mailto:${card2.email}`}>
          E: {card2.email}
        </Detail>
        <Detail as="a" href={`tel:${card2.phone}`}>
          T: {card2.phone}
        </Detail>
        <Detail dangerouslySetInnerHTML={{ __html: card2.address }} />
      </Card>
    </CardsWrapper>
    <Content>
      <SectionHeading id="contact-form">Formularz kontaktowy</SectionHeading>
      <ContactForm
        terms={testTerms}
        info={`Administratorem danych osobowych jest Polisa Centrum Ubezpieczeń Katarzyna Sterzel z siedzibą przy ulicy Zwycięstwa 181 w Koszalinie. Przysługuje Państwu prawo dostępu do swoich danych osobowych oraz prawo do ich usunięcia, poprawiania, ograniczenia przetwarzania oraz cofnięcia zgody na ich przetwarzanie. Podanie danych osobowych jest dobrowolne, wysyłając formularz wyrażają Państwo zgodę na ich przetwarzanie. schowaj Państwa dane będą przetwarzane w celach marketingowych również z wykorzystaniem zautomatyzowanego profilowania Podstawę prawną do przetwarzania Państwa danych osobowych stanowi art. 6 ust. 1 lit.a RODO czyli zgoda w przypadku jej udzielenia lub art. 6 ust. 1 lit.f RODO (ważny interes administratora marketing bezpośredni). Zakres przetwarzanych danych osobowych to imię, nazwisko, funkcja, telefon, adres e-mail. Dane będą przechowywane do momentu wniesienia przez Państwa sprzeciwu. Kontakt z administratorami danych jest możliwy poprzez email: <a href="mailto:polisa@koszalinubezpieczenia">polisa@koszalinubezpieczenia</a>, tel. <a href="tel:+48 888 219 451">+48 888 219 451</a>, oraz korespondencyjnie pod wskazany adres siedziby.`}
      />
    </Content>
  </Section>
);

Partners.propTypes = {
  card1: PropTypes.objectOf(PropTypes.string).isRequired,
  card2: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Partners;
