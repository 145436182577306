import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Icon from 'components/shared/Icon';
import Heading from 'components/shared/Heading';
import Text from 'components/shared/Text';
import icon1 from 'assets/icons/icon1.png';
import icon2 from 'assets/icons/icon2.png';
import icon3 from 'assets/icons/icon3.svg';
import icon4 from 'assets/icons/icon4.png';
import icon5 from 'assets/icons/icon5.png';
import SwiperCore, { Autoplay, Scrollbar, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useMedia from 'hooks/useMedia';
import './swiperStyles.css';

SwiperCore.use([Autoplay, Scrollbar, Pagination]);

const StyledSection = styled(Section)`
  padding-top: 0 !important;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 50px;
  grid-auto-rows: 1fr;
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${({ theme }) => theme.mq.xxl} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const KVCard = styled.figure`
  display: none;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.blue};
  min-height: 380px;
  order: 6;
  ${({ theme }) => theme.mq.xxl} {
    order: 0;
    display: flex;
  }
  &:nth-of-type(4) {
    background: ${({ theme }) => theme.colors.blue100};
  }
  &:nth-of-type(5) {
    display: flex !important;
    background: ${({ theme }) => theme.colors.dark100};
  }
`;

const Card = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 35px 20px;
  min-height: 380px;
  background: ${({ theme }) => theme.colors.white};
  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 9px;
    width: 45%;
    background: ${({ theme }) => theme.colors.blue};
  }
  &:before {
    content: '';
  }
  p {
    text-align: center;
  }
  &:nth-of-type(1):before,
  &:nth-of-type(2):before {
    top: 30px;
    bottom: auto;
    ${({ theme }) => theme.mq.s} {
      top: 0;
    }
  }
  &:nth-of-type(3):before {
    top: 30px;
    bottom: auto;
    ${({ theme }) => theme.mq.s} {
      top: 0;
    }
    ${({ theme }) => theme.mq.xxl} {
      top: auto;
      bottom: 0;
    }
  }
  &:nth-of-type(4):after,
  &:nth-of-type(5):after {
    content: '';
    top: 30px;
    bottom: auto;
    ${({ theme }) => theme.mq.s} {
      top: 0;
    }
  }
  &:after {
    ${({ theme }) => theme.mq.xxl} {
      display: none;
    }
  }
`;

const CardHeading = styled.h4`
  font-size: 2.4rem;
  padding-bottom: 40px;
`;

const SwiperWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

const getIcon = index => {
  switch (index) {
    case 1:
      return icon1;
    case 2:
      return icon2;
    case 3:
      return icon3;
    case 4:
      return icon4;
    case 5:
      return icon5;
    default:
      return null;
  }
};

const Services = ({ heading, cards }) => {
  const matchesS = useMedia('(min-width: 576px)');

  return (
    <StyledSection id="services">
      <Heading>{heading}</Heading>
      {matchesS ? (
        <Wrapper>
          {cards.map(({ heading: cardHeading, description }, index) => (
            <React.Fragment key={cardHeading}>
              <KVCard>
                <Icon auto src={getIcon(index + 1)} alt="icon" />
              </KVCard>
              <Card>
                <CardHeading
                  dangerouslySetInnerHTML={{ __html: cardHeading }}
                />
                <Text dangerouslySetInnerHTML={{ __html: description }} />
              </Card>
            </React.Fragment>
          ))}
        </Wrapper>
      ) : (
        <SwiperWrapper>
          <Swiper
            pagination={{
              clickable: true,
            }}
            scrollbar={{ draggable: true }}
          >
            {cards.map(({ heading: cardHeading, description }) => (
              <SwiperSlide key={cardHeading}>
                <Card>
                  <CardHeading
                    dangerouslySetInnerHTML={{ __html: cardHeading }}
                  />
                  <Text dangerouslySetInnerHTML={{ __html: description }} />
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperWrapper>
      )}
    </StyledSection>
  );
};

Services.propTypes = {
  heading: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Services;
