import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import Header from 'components/Header/Header';
import About from 'components/About/About';
import Process from 'components/Process/Process';
import Services from 'components/Services/Services';
import Partners from 'components/Partners/Partners';
import Contact from 'components/Contact/Contact';
import News from 'components/News/News';
import { formatGMTDate } from '../utils/formatDate';

const IndexPage = ({ data }) => {
  console.log(data);

  const about1Img = data.about1Img.childImageSharp.fluid;
  const about2Img = data.about2Img.childImageSharp.fluid;
  const aboutBG = data.aboutBG.childImageSharp.fluid;
  const process1Img = data.process1Img.childImageSharp.fluid;
  const process2Img = data.process2Img.childImageSharp.fluid;
  const process3Img = data.process3Img.childImageSharp.fluid;
  const partners = data.partners.nodes;

  const slides = data.slides.nodes.map(slide => ({
    image: slide.childImageSharp.fluid,
    imageAlt: slide.name,
  }));

  const aboutSlides = [
    {
      image: about1Img,
      imageAlt: 'slide 1',
    },
    {
      image: about2Img,
      imageAlt: 'slide 2',
    },
  ];

  const cards = [
    {
      image: process1Img,
      imageAlt: 'test',
      title: 'Przedstawienie ofert',
      description:
        'Szybko dopasujemy ofertę do Państwa potrzeb i zaoferujemy najlepsze rozwiązania w konkurencyjnej cenie.',
    },
    {
      image: process2Img,
      imageAlt: 'test',
      title: 'Podpisanie dokumentów',
      description:
        'Transparentnie przeprowadzimy Państwa przez proces zawierania umowy dbając o Państwa bezpieczeństwo.',
    },
    {
      image: process3Img,
      imageAlt: 'test',
      title: 'Gotowe ubezpieczenie',
      description:
        'Od tej pory są Państwo kolejnymi zadowolonymi posiadaczami ubezpieczenia w naszej firmie.',
    },
  ];

  const processCards = [
    {
      heading: 'Ubezpieczenie na życie',
      description:
        'Przy wyborze trzeba zastanowić się nad dogodniejszą opcją, czy interesuje Państwa ubezpieczenie grupowe, czy dopasowane do indywidualnych potrzeb.',
    },
    {
      heading: 'Ubezpieczenie komunikacyjne',
      description:
        'W naszej ofercie znajdują się obowiązkowe na terenie Polski ubezpieczenia OC oraz dobrowolne NNW, Autocasco, Assistance oraz dodatkowe inne świadczenia.',
    },
    {
      heading: 'Ubezpieczenia mienia',
      description:
        'Ubezpieczenie nieruchomości pozwala uchronić swój dom i wyposażenie przed zdarzeniami losowymi, wandalizmem lub awarią.',
    },
    {
      heading: 'Ubezpieczenia turystyczne',
      description:
        'Ubezpieczenie turystyczne jest dobrowolne i mają na celu zapewnienie ochrony podczas wyjazdów za granicę niezależnie od celu podróży.',
    },
    {
      heading: 'Ubezpieczenia inne',
      description:
        'Odpowiadając na potrzeby klientów oferujemy równieższereg innych możliwości ubezpieczeń np. telefonu komórkowego lub roweru.',
    },
  ];

  const NEWS_DATA = {
    heading: 'Aktualności ubezpieczeniowe <br> i porady klientów',
    cards: data.allWpPost.nodes.map(node => ({
      title: node.title,
      slug: node.slug,
      date: formatGMTDate(node.dateGmt),
      blogTime: node.blog.blogTime,
      blogExpert: node.blog.blogExpert,
      blogImg: node.blog.blogImg.localFile.childImageSharp.fluid,
    })),
  };

  return (
    <>
      <SEO title="Strona główna" />
      <Header
        heading="Polisa na życie.<br/>Ubezpiecz się z nami"
        slides={slides}
      />
      <main>
        <About
          heading="O nas"
          slides={aboutSlides}
          bgImage={aboutBG}
          content="W naszej agencji ubezpieczeń świadczymy profesjonalne usługi ubezpieczeniowe. Oferujemy szereg możliwości ubezpieczeniowych oraz pakietów, co sprawia, że dopasujemy ofertę do Państwa potrzeb w każdym calu. Dzięki wieloletniemu doświadczeniu jesteśmy w stanie w szybki i przejrzysty sposób przeprowadzać naszych klientów przez cały proces, dzięki czemu cieszymy się zadowoleniem wielu osób."
        />
        <Process
          heading="W ten sposób prowadzimy nasze procesy"
          cards={cards}
        />
        <Services heading="Usługi" cards={processCards} />
        <Partners heading="Nasi partnerzy" cards={partners} />
        <News {...NEWS_DATA} />
        <Contact
          card1={{
            heading: 'Chcesz się ubezpieczyć?',
            btnText: 'Napisz do nas',
          }}
          card2={{
            heading: 'Dane kontaktowe',
            email: 'polisa@koszalinubezpieczenia.pl',
            phone: '+48 888 219 451',
            address: 'ul. Zwycięstwa 181<br/>75 - 900 Koszalin',
          }}
        />
      </main>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryHomePage {
    slides: allFile(filter: { relativeDirectory: { eq: "images/slides" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    about1Img: file(name: { eq: "about1" }) {
      childImageSharp {
        fluid(maxHeight: 850) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    about2Img: file(name: { eq: "about2" }) {
      childImageSharp {
        fluid(maxHeight: 850) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aboutBG: file(name: { eq: "about-bg" }) {
      childImageSharp {
        fluid(maxHeight: 850) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    process1Img: file(name: { eq: "process1" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    process2Img: file(name: { eq: "process2" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    process3Img: file(name: { eq: "process3" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    partners: allFile(
      filter: { relativeDirectory: { eq: "images/partners" } }
    ) {
      nodes {
        name
        publicURL
      }
    }

    news: allWpPost {
      edges {
        node {
          title
        }
      }
    }

    allWpPost(limit: 3) {
      nodes {
        title
        slug
        dateGmt
        blog {
          blogTime
          blogExpert
          blogImg {
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
