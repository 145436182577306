import styled from 'styled-components';

const Text = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.light};
  font-size: 1.5rem;
  line-height: 2.8rem;
  text-align: justify;
  ${({ theme }) => theme.mq.xs} {
    font-size: 1.6rem;
    text-align: ${({ align }) => align || 'left'};
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 1.7rem;
    line-height: 3rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    line-height: 4rem;
  }
`;

export default Text;
