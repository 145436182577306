/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import useMedia from 'hooks/useMedia';
import axios from 'axios';

const StyledForm = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
  margin-top: 20px;
  ${({ theme }) => theme.mq.s} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-top: 0;
  }
`;

const InputsWrapper = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(1, 1fr);
  ${({ theme }) => theme.mq.s} {
    gap: 40px 50px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const InputLabel = styled.label`
  position: absolute;
  top: -15px;
  left: 5px;
  font-size: 1.5rem;
  opacity: 0;
  transition: 0.2s;
  ${({ error }) =>
    error &&
    css`
      opacity: 1 !important;
      color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ valid }) =>
    valid &&
    css`
      opacity: 1 !important;
      color: ${({ theme }) => theme.colors.dark};
    `}
`;

const Input = styled.input`
  position: relative;
  width: 100%;
  padding: 15px 5px;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  font-weight: ${({ theme }) => theme.light};
  overflow: visible;
  transition: 0.3s;
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark};
  &:focus {
    border-color: ${({ theme }) => theme.colors.blue100};
    ::placeholder {
      opacity: 0;
    }
  }
  &:focus + ${InputLabel} {
    opacity: 1;
  }
  ${({ theme }) => theme.mq.s} {
    padding: 20px 5px;
    font-size: 2rem;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.dark};
    font-family: ${({ theme }) => theme.fonts.mainFont};
    opacity: 1;
  }
  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ valid }) =>
    valid &&
    css`
      border-color: ${({ theme }) => theme.colors.green};
    `}
`;

const Textarea = styled(Input)`
  flex: 1;
  resize: none;
  font-family: inherit;
  font-weight: ${({ theme }) => theme.light};
  height: 100px;
  ::placeholder {
    line-height: 100px;
  }
`;

const InputItem = styled.div`
  position: relative;
  margin-top: auto;
`;

const FormFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.light};
  & > button {
    margin: 0 auto;
  }
  ${({ theme }) => theme.mq.md} {
    margin: 20px 0 0;
    flex-direction: row;
    justify-content: space-between;
    & > button {
      margin: 0 0 0 auto;
    }
  }
`;

const ErrorsWrapper = styled.div`
  display: flex;
  text-align: left;
`;

const Address = styled.address`
  width: 100%;
  flex-basis: 50%;
  order: 1;
  margin-top: 40px;
  ${({ theme }) => theme.mq.md} {
    order: 0;
    margin-top: 0;
  }
`;

const FormFooterInner = styled.div`
  flex-basis: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.colors.red100};
  line-height: 18px;
  padding-bottom: 20px;
`;

const MessageContent = styled(ErrorMessage)`
  margin: -5px 0 0;
  opacity: ${({ color }) => (color ? '1' : '0')};
  visibility: ${({ color }) => (color ? 'visible' : 'hidden')};
  font-size: ${({ theme }) => theme.fontSize.md};
  ${({ theme }) => theme.mq.s} {
    margin: -30px 0 0;
  }
  ${({ color }) =>
    color === 'red' &&
    css`
      color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ color }) =>
    color === 'green' &&
    css`
      color: ${({ theme }) => theme.colors.green};
      &:before {
        display: none;
      }
    `}
`;

const StyledText = styled(Text)`
  padding: 0;
  text-decoration: none;
  ${({ theme }) => theme.mq.s} {
    padding: 10px 0;
  }
`;

const Terms = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 0;
  ${({ theme }) => theme.mq.md} {
    padding: 30px 0;
  }
`;

const LabelText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  a {
    color: ${({ theme }) => theme.colors.blue100};
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
    `}
`;

const Label = styled.label`
  position: relative;
  display: flex;
  font-weight: ${({ theme }) => theme.regular};
  font-size: ${({ theme }) => theme.fontSize.xs};
  user-select: ${({ autoCursor }) => (autoCursor ? 'auto' : 'none')};
  line-height: 18px;
  cursor: ${({ autoCursor }) => (autoCursor ? 'auto' : 'pointer')};
  flex: 1;
  text-align: justify;
  margin-bottom: 20px;
  &:not(:last-child) {
    padding-left: 35px;
  }
`;

const LabelBtn = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.fonts.mainFont};
  cursor: pointer;
  align-self: flex-start;
  padding: 2px 0 2px 10px;
  margin-left: auto;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  height: 22px;
  width: 22px;
  border: 1px solid;
  border-radius: 50%;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red100 : theme.colors.white};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.15);
  transition: 0.2s;
  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 4px;
    top: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.blue};
    transition: 0.2s;
    opacity: 0;
  }
`;

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark}:after {
    opacity: 1;
  }
`;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const contactSchema = Yup.object().shape({
  name: Yup.string().required('Proszę podać imię i nazwisko'),
  email: Yup.string()
    .email('Adres email jest nieprawidłowy')
    .required('Proszę podać adres e-mail'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Numer telefonu jest nieprawidłowy')
    .required('Proszę podać numer telefonu'),
  message: Yup.string().required('Proszę podać treść wiadomości'),
  terms: Yup.bool().oneOf([true], 'Proszę o zaznaczenie zgód'),
});

const ContactForm = ({ terms, info }) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [expandedTerms, setExpandedTerms] = useState([]);
  const [formMessage, setFormMessage] = useState({ text: '', color: '' });

  const formTerms = terms.reduce((obj, item) => {
    obj[item.name] = false;
    return obj;
  }, {});

  const handleCheckbox = name => {
    if (expandedTerms.includes(name)) {
      setExpandedTerms(expandedTerms.filter(term => term !== name));
    } else setExpandedTerms([...expandedTerms, name]);
  };

  const matchesS = useMedia('(min-width: 576px)');

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        message: '',
        ...formTerms,
      }}
      validationSchema={contactSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const sendEmail = async () => {
          if (terms.some(term => term.required && values[term.name] === false))
            return setSubmitting(false);

          const { name, email, phone, message } = values;

          const params = new FormData();

          params.set('name', name);
          params.set('email', email);
          params.set('phone', phone);
          params.set('message', message);
          params.set('consent1', values.terms);
          params.set(
            'url',
            (typeof window !== 'undefined' && window.location.href) || ''
          );

          try {
            setSubmitting(true);

            const { data } = await axios.post(
              `https://polisa.indigital.guru/mailer/mailer-main.php`,
              params
            );

            setSubmitting(false);
            setFormMessage({
              text: data.message || 'Twoja wiadomość została wysłana',
              color: 'green',
            });

            setTimeout(() => {
              setFormMessage({ text: '', color: '' });
              resetForm();
            }, 2500);
          } catch (err) {
            setFormMessage({
              text: 'Coś poszło nie tak',
              color: 'red',
            });

            setTimeout(() => {
              setSubmitting(false);
              setFormMessage({ text: '', color: '' });
            }, 1500);
          }
        };
        sendEmail();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setErrors,
        setFieldValue,
        isSubmitting,
      }) => (
        <StyledForm onSubmit={handleSubmit} autoComplete="off">
          <InputsWrapper>
            <InputItem>
              <Input
                id="name"
                name="name"
                placeholder="Imię"
                onChange={handleChange}
                onBlur={handleBlur}
                valid={touched.name && !errors.name}
                error={submitClicked && errors.name && touched.name}
                value={values.name}
                touched={touched.name}
                errors={errors.name}
              />
              <InputLabel
                valid={touched.name && !errors.name}
                error={submitClicked && errors.name && touched.name}
                htmlFor="name"
              >
                {(submitClicked &&
                  errors.name &&
                  touched.name &&
                  errors.name) ||
                  'Imię'}
              </InputLabel>
            </InputItem>
            <InputItem>
              <Input
                id="email"
                name="email"
                placeholder="E-mail"
                onChange={handleChange}
                onBlur={handleBlur}
                valid={touched.email && !errors.email}
                error={submitClicked && errors.email && touched.email}
                value={values.email}
                touched={touched.email}
                errors={errors.email}
              />
              <InputLabel
                valid={touched.email && !errors.email}
                error={submitClicked && errors.email && touched.email}
                htmlFor="email"
              >
                {(submitClicked &&
                  errors.email &&
                  touched.email &&
                  errors.email) ||
                  'E-mail'}
              </InputLabel>
            </InputItem>
            <InputItem>
              <Input
                id="phone"
                name="phone"
                placeholder="Telefon"
                onChange={handleChange}
                onBlur={handleBlur}
                valid={touched.phone && !errors.phone}
                error={submitClicked && errors.phone && touched.phone}
                value={values.phone}
                touched={touched.phone}
                errors={errors.phone}
              />
              <InputLabel
                valid={touched.phone && !errors.phone}
                error={submitClicked && errors.phone && touched.phone}
                htmlFor="phone"
              >
                {(submitClicked &&
                  errors.phone &&
                  touched.phone &&
                  errors.phone) ||
                  'Telefon'}
              </InputLabel>
            </InputItem>
            <InputItem>
              <Textarea
                as="textarea"
                id="message"
                name="message"
                placeholder="Wiadomość"
                spellCheck="false"
                onChange={handleChange}
                onBlur={handleBlur}
                valid={touched.message && !errors.message}
                error={submitClicked && errors.message && touched.message}
                value={values.message}
                touched={touched.message}
                errors={errors.message}
              />
              <InputLabel
                valid={touched.message && !errors.message}
                error={submitClicked && errors.message && touched.message}
                htmlFor="message"
              >
                {(submitClicked &&
                  errors.message &&
                  touched.message &&
                  errors.message) ||
                  'Wiadomość'}
              </InputLabel>
            </InputItem>
          </InputsWrapper>
          <FormFooter>
            <Address>
              <StyledText>
                <b>Adres korespondencyjny</b>
              </StyledText>
              <StyledText>Polisa Centrum Ubezpieczeń</StyledText>
              <StyledText>ul. Zwycięstwa 181, 75-900 Koszalin</StyledText>
              <StyledText as="a" href="mailto:polisa@koszalinubezpieczenia.pl">
                <b>Email: </b>polisa@koszalinubezpieczenia.pl
              </StyledText>
              <br />
              {matchesS && <br />}
              <StyledText as="a" href="tel:+48 888 219 451">
                <b>Telefon: </b>+48 888 219 451
              </StyledText>
            </Address>
            <FormFooterInner>
              <Terms>
                {terms.length &&
                  terms.map(({ name, content, required }) => (
                    <Label htmlFor={name} key={content}>
                      <LabelText expanded={expandedTerms.includes(name)}>
                        {content}
                      </LabelText>
                      <LabelBtn
                        type="button"
                        onClick={() => handleCheckbox(name)}
                      >
                        {expandedTerms.includes(name) ? 'Mniej' : 'Więcej'}
                      </LabelBtn>
                      <Checkbox
                        id={name}
                        name={name}
                        type="checkbox"
                        onChange={() => {
                          setFieldValue(name, !values[name]);
                          required && setErrors({ ...errors, terms: '' });
                        }}
                        checked={values[name]}
                      />
                      <Checkmark
                        error={submitClicked && errors.terms && required}
                      />
                    </Label>
                  ))}
                <ErrorsWrapper>
                  {submitClicked && errors.terms && (
                    <ErrorMessage>{errors.terms}</ErrorMessage>
                  )}
                </ErrorsWrapper>
                <Label autoCursor htmlFor="contact-info">
                  <LabelText
                    expanded={expandedTerms.includes('contact-info')}
                    dangerouslySetInnerHTML={{ __html: info }}
                  />
                  <LabelBtn
                    type="button"
                    onClick={() => handleCheckbox('contact-info')}
                  >
                    {expandedTerms.includes('contact-info')
                      ? 'Mniej'
                      : 'Więcej'}
                  </LabelBtn>
                  <Checkbox
                    id="contact-info"
                    name="contact-info"
                    type="checkbox"
                  />
                </Label>
              </Terms>
              {formMessage.text && (
                <MessageContent color={formMessage.color}>
                  {formMessage.text}
                </MessageContent>
              )}
              <Button
                onClick={() => setSubmitClicked(true)}
                submit
                disabled={isSubmitting}
              >
                {isSubmitting ? '' : 'Wyślij'}
              </Button>
            </FormFooterInner>
          </FormFooter>
        </StyledForm>
      )}
    </Formik>
  );
};

ContactForm.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.object).isRequired,
  info: PropTypes.string.isRequired,
};

export default ContactForm;
