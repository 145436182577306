import React from 'react';

import Section from 'components/shared/Section';

import * as S from './News.styles';
import { Link } from 'gatsby';

const News = ({ heading, cards }) => {
  return (
    <Section>
      <S.StyledContent>
        <S.Heading>
          <S.HeadingText
            dangerouslySetInnerHTML={{
              __html: heading,
            }}
          />
        </S.Heading>

        <S.CardsWrapper>
          {cards.map(card => (
            <S.Card as={Link} to={`/${card.slug}`}>
              <S.ImageWrapper>
                <S.Image src={card.blogImg} cover />
              </S.ImageWrapper>

              <S.CardContent>
                <S.CardContentHeading>
                  <S.Date
                    dangerouslySetInnerHTML={{
                      __html: card.date,
                    }}
                  />

                  <S.Title
                    dangerouslySetInnerHTML={{
                      __html: card.title,
                    }}
                  />
                </S.CardContentHeading>

                <S.BottomText
                  dangerouslySetInnerHTML={{
                    __html: `<b>${card.blogExpert}</b> <br> Czas czytania: ${card.blogTime}`,
                  }}
                />
              </S.CardContent>
            </S.Card>
          ))}
        </S.CardsWrapper>
      </S.StyledContent>
    </Section>
  );
};

export default News;
