import styled from 'styled-components';

const Heading = styled.h1`
  font-size: 3.3rem;
  line-height: 4rem;
  font-weight: ${({ theme }) => theme.light};
  color: #282828;
  text-align: ${({ left }) => (left ? 'left' : 'center')};
  padding: 20px 0;
  ${({ theme }) => theme.mq.xs} {
    font-size: 4rem;
    line-height: 5rem;
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 5rem;
    line-height: 6rem;
  }
  ${({ theme }) => theme.mq.md} {
    font-size: 5rem;
    line-height: 6rem;
    padding: 30px 0;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: ${({ theme }) => theme.fontSize.xxl};
    line-height: 7.5rem;
    padding: 40px 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: ${({ theme }) => theme.fontSize.xxxl};
    line-height: 9rem;
    padding: 50px 0;
  }
`;

export default Heading;
